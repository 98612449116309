<template>
  <section class="recentposts">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-md-10 col-8">
          <h3>Related posts</h3>
          <p style="padding: 0px">
            More posts from this Author, and in this sector.
          </p>
        </div>
        <!-- <div class="col-lg-2 col-md-2 col-4 text-center">
          <Button :text="buttontText" />
        </div> -->
        <div
          class="col-lg-4 col-md-4 col-12 pt-4 d-flex align-items-stretch"
          v-for="(obj, key) in featureareaJSON"
          :key="key"
        >
          <div class="icon-box">
            <Image :fpath="obj.path" class="serviceimage" />
            <h4 class="softwarehead">{{ obj.header }}</h4>
            <p>{{ obj.caption }}</p>
            <p><a :href="`../${obj.link}`">Read More</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import Button from "@/components/Button.vue";
import Image from "@/components/Image.vue";
export default {
  components: {
    Image,
    // Button,
  },
  data: () => ({
    buttontText: "View all",
    featureareaJSON: [
      {
        path: "Airline.jpg",
        header: "Airline Traffic Analytics",
        link: "blogs/subscription-revenue-management",
        caption:
          "International airline traffic analytics dashboard provides a quick look at KPI’s related to aviation market in Australia. Metrics include key growth parameters and long term patterns related to Passengers & Cargo arriving from and departing to various destinations across the world.",
      },
      {
        path: "Time-Series.jpg",
        header: "Time Series Analysis – International Air Traffic",
        link: "time-series",
        caption:
          "Time series analysis, a popular and effective tool to analyze historical data, identify patterns and predict the future trends. “Air Passengers” is a popular measure considered in many research articles pertaining to time series analysis and validating effectiveness of prediction output.",
      },
      {
        path: "Airport.jpg",
        header: "Airport Traffic Movement Analysis",
        link: "airport-traffic",
        caption:
          "Air Traffic is driven by demand for passengers as well as cargo, but the majority of revenue generated by airlines are through transporting passengers from source to destination. This demo looks at traffic patterns on annual basis for operating airports in Australia.",
      },
    ],
  }),
};
</script>
<style scoped>
.recentblogs {
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}
img.serviceimage {
  width: 100%;
  padding-bottom: 15px;
  border-radius: 15px;
}
.icon-box:hover {
  background: #f7f9fd;
  cursor: pointer;
  border-color: #0880e8;
}
.icon-box {
  margin-bottom: 10px;
  background: #fff;
  border-radius: 15px;
  padding: 0px 0px 20px 0px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.recentposts h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5em;
  padding: 10px;
  color: #0880e8;
}
.recentposts p {
  font-size: 16px;
  padding: 0px 10px;
}
.recentposts a {
  font-size: 14px;
  padding: 10px;
  color: #0880e8;
}
.recentposts {
  padding: 30px 0px;
  background: #f6f6f6;
}

@media (max-width: 998px) and (min-width: 0px) {
  .recentposts {
    padding: 30px;
  }
}
</style>
